.css-13cymwt-control {
    height: 45px;
    box-shadow: none;
    border-radius: 0 !important;
    border: 1px solid #e8e8e8 !important;
    background: #fff;
}

.frontCss #contact_form li input {
    height: auto;
}

.css-t3ipsp-control {
    height: 45px;
}