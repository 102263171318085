/********** Template CSS **********/
:root {
    --primary: #EB1616;
    --secondary: #191C24;
    --light: #6C7293;
    --dark: #000000;
    --white: #ffffff;
}

.adminCss .back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}

.adminCss #spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
    z-index: 99999;
}

.adminCss #spinner.show {
    transition: opacity 0.5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}

.adminCss .btn {
    transition: 0.5s;
}

.adminCss .btn-square {
    width: 38px;
    height: 38px;
}

.adminCss .btn-sm-square {
    width: 32px;
    height: 32px;
}

.adminCss .btn-lg-square {
    width: 48px;
    height: 48px;
}

.adminCss .btn-square,
.adminCss .btn-sm-square,
.adminCss .btn-lg-square {
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

.adminCss .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    background: var(--secondary);
    transition: 0.5s;
    z-index: 999;
}

.adminCss .content {
    margin-left: 250px;
    min-height: 100vh;
    background: var(--white);
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width: 992px) {
    .adminCss .sidebar {
        margin-left: 0;
    }

    .adminCss .sidebar.open {
        margin-left: -250px;
    }

    .adminCss .content {
        width: calc(100% - 250px);
    }

    .adminCss .content.open {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 991px) {
    .adminCss .sidebar {
        margin-left: -250px;
    }

    .adminCss .sidebar.open {
        margin-left: 0;
    }

    .adminCss .content {
        width: 100%;
        margin-left: 0;
    }
}

.adminCss .sidebar .navbar .navbar-nav .nav-link {
    padding: 7px 20px;
    color: var(--light);
    font-weight: 500;
    border-left: 3px solid var(--secondary);
    border-radius: 0 30px 30px 0;
    outline: none;
}

.adminCss .sidebar .navbar .navbar-nav .nav-link:hover,
.adminCss .sidebar .navbar .navbar-nav .nav-link.active {
    color: var(--primary);
    background: var(--dark);
    border-color: var(--primary);
}

.adminCss .sidebar .navbar .navbar-nav .nav-link i, .adminCss .sidebar .navbar .navbar-nav .nav-link em {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--dark);
    border-radius: 40px;
}

.adminCss .sidebar .navbar .navbar-nav .nav-link:hover i,
.adminCss .sidebar .navbar .navbar-nav .nav-link.active i,
.adminCss .sidebar .navbar .navbar-nav .nav-link:hover em,
.adminCss .sidebar .navbar .navbar-nav .nav-link.active em {
    background: var(--secondary);
}

.adminCss .sidebar .navbar .dropdown-toggle::after {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
    transition: 0.5s;
}

.adminCss .sidebar .navbar .dropdown-toggle[aria-expanded=true]::after {
    transform: rotate(-180deg);
}

.adminCss .sidebar .navbar .dropdown-item {
    padding-left: 25px;
    border-radius: 0 30px 30px 0;
    color: var(--light);
}

.adminCss .sidebar .navbar .dropdown-item:hover,
.adminCss .sidebar .navbar .dropdown-item.active {
    background: var(--dark);
}

.adminCss .content .navbar .navbar-nav .nav-link {
    margin-left: 25px;
    padding: 12px 0;
    color: var(--light);
    outline: none;
}

.adminCss .content .navbar .navbar-nav .nav-link:hover,
.adminCss .content .navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

.adminCss .content .navbar .sidebar-toggler,
.adminCss .content .navbar .navbar-nav .nav-link i, .adminCss .content .navbar .navbar-nav .nav-link em {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--dark);
    border-radius: 40px;
}

.adminCss .content .navbar .dropdown-item {
    color: var(--light);
}

.adminCss .content .navbar .dropdown-item:hover,
.adminCss .content .navbar .dropdown-item.active {
    background: var(--dark);
}

.adminCss .content .navbar .dropdown-toggle[aria-expanded=true]::after {
    transform: rotate(-180deg);
}

@media (max-width: 575px) {
    .adminCss .content .navbar .navbar-nav .nav-link {
        margin-left: 15px;
    }
}

.adminCss .bootstrap-datetimepicker-widget.bottom {
    top: auto !important;
}

.adminCss .bootstrap-datetimepicker-widget .table * {
    border-bottom-width: 0;
}

.adminCss .bootstrap-datetimepicker-widget .table th {
    font-weight: 500;
}

.adminCss .bootstrap-datetimepicker-widget.dropdown-menu {
    padding: 10px;
    border-radius: 2px;
}

.adminCss .bootstrap-datetimepicker-widget table td.active,
.adminCss .bootstrap-datetimepicker-widget table td.active:hover {
    background: var(--primary);
}

.adminCss .bootstrap-datetimepicker-widget table td.today::before {
    border-bottom-color: var(--primary);
}

.adminCss .progress .progress-bar {
    width: 0;
    transition: 2s;
}

.adminCss .testimonial-carousel .owl-dots {
    margin-top: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.adminCss .testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    border: 5px solid var(--primary);
    border-radius: 15px;
    transition: 0.5s;
}

.adminCss .testimonial-carousel .owl-dot.active {
    background: var(--dark);
    border-color: var(--primary);
}

.upload-images-form div[role="presentation"],
.upload-slides-form div[role="presentation"] {
    border: 1px solid;
    padding: 32px 20px;
    text-align: center;
    border-radius: 8px;
}

.adminCss .admin-gallery-btn, .adminCss .admin-banner-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}

.adminCss .event-description-pre {
    white-space: pre-wrap;
    height: 170px;
    overflow: auto;
}